import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { conversionTypes } from "../integration/gtag"
import ConversionLink from "../components/conversion-link"
import logo from "!url-loader?limit='true'!../images/logo.png"

const Header = (props) => {
  const { phone } = props
  const { title } = useSiteMetadata()
  const [showNav, setShowNav] = React.useState(false)

  const imageData = useStaticQuery(graphql`
    query {
      keys: file(relativePath: { eq: "keys-scattered.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const handleNavbarToggle = () => {
    setShowNav(!showNav)
  }

  return (
    <header>
      <Img
        alt={title}
        className="header-bg-image"
        fluid={imageData.keys.childImageSharp.fluid}
      />
      <div className="navigation">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <Link className="navbar-brand" to="/">
              <img alt={title} src={logo} />
            </Link>
            <button
              className={`navbar-toggler ${showNav ? "" : "showing"}`}
              type="button"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleNavbarToggle}
            >
              <div className="icon-wrap">
                <span className="fas fa-bars" />
              </div>
              <div className="">
                <span className="fas fa-times" />
              </div>
            </button>

            <div
              className={`navbar-collapse ${showNav ? "show" : "collapse"}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">
                    ראשי
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/services">
                    סוגי שירותים
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/locations">
                    אזורי שירות
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contact">
                    צור קשר
                  </Link>
                </li>
                {/* <li className="nav-item">
                    <Link className="nav-link" to="about">
                      About
                    </Link>
                  </li> */}
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <section className="subtitle">
        <div className="d-inline-block">שירותי מנעולנות מקצועיים </div>{" "}
        <div className="d-inline-block">באזור המרכז, הרצליה והשרון</div>
      </section>
      <div className="top-header">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-12">
              <ul
                className="list-inline text-lg-right text-center"
                style={{ lineHeight: 1.2, marginBottom: "0.7rem" }}
              >
                <li className="list-inline-item d-inline-block">ננעלתם?</li>
                <li className="list-inline-item separate">תתקשרו &nbsp;</li>
                <li>
                  <ConversionLink
                    href={`tel:${phone}`}
                    className="phone-number"
                    dir="ltr"
                    conversionId={conversionTypes.phoneHeader}
                  >
                    <i className="fas fa-phone" />
                    <span className="ml-2">{phone}</span>
                  </ConversionLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
