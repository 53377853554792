import React from "react"
import { reportConversion } from "../integration/gtag"

const ConversionLink = (props) => {
  const { href, dir, className, style, children, conversionId } = props

  const handleClick = () => {
    reportConversion(conversionId, href)
  }

  return (
    <a
      {...{ href, dir, className, style }}
      aria-label={props['aria-label']}
      onClick={handleClick}
    >
      {children}
    </a>
  )
}

export default ConversionLink
