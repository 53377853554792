/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Hotjar from "@hotjar/browser"
import { useSiteMetadata } from "../hooks/use-site-metadata"
import { conversionTypes } from "../integration/gtag"
import ConversionLink from "../components/conversion-link"
import "bootstrap-css-only/css/bootstrap.css"

import Header from "./header"
import Slideshow from "./slideshow"

if (typeof window !== 'undefined') {
  const siteId = 4984424
  const hotjarVersion = 6

  Hotjar.init(siteId, hotjarVersion)
}

const Layout = ({ children, showSlideshow }) => {
  const { phone, title, whatsapp } = useSiteMetadata()
  const imageData = useStaticQuery(graphql`
    query {
      photo1: file(relativePath: { eq: "bannerphoto.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      photo2: file(relativePath: { eq: "bannerphoto2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      photo3: file(relativePath: { eq: "bannerphoto3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const images = [
    {
      ...imageData.photo1,
      alt: "מנעולן פורץ רכב",
    },
    {
      ...imageData.photo2,
      alt: "ידיים פותחות דלת",
    },
    {
      ...imageData.photo3,
      alt: "מפתחות בתוך דלת עץ",
    },
  ]

  return (
    <div className="wrap">
      {/* <script
        src="https://kit.fontawesome.com/62aef7387a.js"
        crossorigin="anonymous"
      /> */}
      {/* <script src="https://www.gstatic.com/firebasejs/7.24.0/firebase-app.js" />

      <script src="https://www.gstatic.com/firebasejs/7.24.0/firebase-analytics.js" /> */}
      <Header phone={phone} />
      {showSlideshow && (
        <section className="banner">
          <div className="container-fluid">
            <div className="row">
              <Slideshow images={images} defaultAlt={title} />
            </div>
          </div>
        </section>
      )}
      <main className="main-content">
        <div className="container">
          {children}
        </div>
        <div className="floating-buttons d-sm-none">
          <ConversionLink
            href={`https://wa.me/${whatsapp}`}
            className="floating-button"
            aria-label="WhatsApp"
            style={{
              background: "#25D366",
            }}
            conversionId={conversionTypes.whatsAppFloating}
          >
            <i className="fa fab fa-whatsapp" />
          </ConversionLink>
          <ConversionLink
            href={`tel:${phone}`}
            className="floating-button"
            aria-label="טלפון"
            style={{ background: "rgb(224 128 14)" }}
            conversionId={conversionTypes.phoneFloating}
          >
            <i className="fa fa-phone" />
          </ConversionLink>
        </div>
      </main>
      <footer>
        <div className="container">
          <div className="row mx-auto">
            <div className="col-sm mb-4">
              <h4>שעות פעילות:</h4>
              <div className="d-flex">
                <div className="ml-4">
                  <div>יום א' - ה':</div>
                  <div> שישי - שבת:</div>
                </div>
                <div>
                  <div>7:00 - 21:00</div>
                  <div>7:00 - 21:00</div>
                </div>
              </div>
              {/* I don't think this is necessary unless we need a really tiny breakpoint.
                Leaving it for now if we need a differently arranged hours section.
              */}
              {/* <p className="row d-sm-none">
                <div className="me-1">
                  <div>יום א' - ה':</div>
                  <div>7:00 - 21:00</div>
                  <div> שישי - שבת:</div>
                  <div>7:00 - 21:00</div>
                </div>
              </p> */}
            </div>
            <div className="col-sm">
              <div>
                <h4>צור קשר:</h4>
                <ConversionLink
                  href={`tel:${phone}`}
                  conversionId={conversionTypes.phoneFooter}
                >
                  <i className="fa fa-phone" /> {phone}
                </ConversionLink>
                <br />
                <ConversionLink
                  href={`https://wa.me/${whatsapp}`}
                  lang="en"
                  conversionId={conversionTypes.whatsAppFooter}
                >
                  <i className="fa fab fa-whatsapp" />
                  WhatsApp
                </ConversionLink>
                <br />
                <Link to="/contact">
                  <i className="fa fa-envelope" /> דואר אלקטרוני
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="navbar navbar-bottom">
          <ul className="nav m-auto d-flex justify-content-center">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                ראשי
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/services">
                סוגי שירותים
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/locations">
                אזורי שרות
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">
                צור קשר
              </Link>
            </li>
            {/* <li className="nav-item">
                    <Link className="nav-link" to="about">
                      About
                    </Link>
                  </li> */}
          </ul>
        </div>
        <div className="text-center p1 container mx-auto">
          <div className="d-inline-block">
            {'מוסמך ע"י חבר המנעולנים בישראל'}
          </div>{" "}
          <br />
          <br />
          <div className="d-inline-block">
            כל הזכויות שמורות {new Date().getFullYear()} ©
          </div>{" "}
          <div className="d-inline-block">{title}</div>
          <br />
          <br />
          <small>
            <Link className="nav-link" to="/privacy" style={{ color: 'inherit' }}>
              מדיניות פרטיות
            </Link>
          </small>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
