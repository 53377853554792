import * as React from "react"
import Img from "gatsby-image"
import "./slideshow.css"

const { useEffect, useState } = React

const Slideshow = ({ images, defaultAlt }) => {
  const [activeIdx, setActiveIdx] = useState(0)
  const interval = 5000

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveIdx((activeIdx) => (activeIdx + 1) % images.length)
    }, interval)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return (
    <div className="screen">
      <Img
        critical
        loading="eager"
        style={{ visibility: "hidden" }}
        fluid={images[0].childImageSharp.fluid}
        alt={images[0].alt || defaultAlt}
      />
      {images.map((image, i) => (
        <Img
          critical={i === 0}
          loading={i === 0 ? 'eager' : 'auto'}
          key={`${i}{image.alt}`}
          fluid={image.childImageSharp.fluid}
          alt={image.alt || defaultAlt}
          className={`col-xs-12 no-gutters slide ${
            i === activeIdx ? "show" : ""
          }`}
        />
      ))}
    </div>
  )
}

export default Slideshow
