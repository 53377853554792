export const conversionTypes = {
  phoneFloating: 'AW-10865717162/uk1LCP3k27EDEKrflr0o',
  phoneHeader: 'AW-10865717162/MV6kCMuf-LEDEKrflr0o',
  phoneFooter: 'AW-10865717162/Hte8COTN_bEDEKrflr0o',
  phoneContactPage: 'AW-10865717162/v25BCJfO_bEDEKrflr0o',
  phoneHomepageCopy: 'AW-10865717162/Q7nOCOWvvLoZEKrflr0o',
  whatsAppFloating: 'AW-10865717162/-mTkCI-qxbEDEKrflr0o',
  whatsAppFooter: 'AW-10865717162/2CfuCLff_bEDEKrflr0o'
};

/**
 * @param {string} conversionId 
 * @param {() => void} [url]
 * @returns false
 */
export function reportConversion(conversionId, url) {
  const callback = () => {
    if (url) {
      window.location = url
    }
  };

  window.gtag('event', 'conversion', {
    'send_to': conversionId,
    'event_callback': callback
  });

  return false;
}
